import "./styles.scss"
import React, { useState } from 'react';
import CustomWelcomeMessageForm from "modules/website/pages/edit/components/customWelcomeMessageForm";
import BabyFundsForm from "modules/website/pages/edit/components/babyFundsForm";
import PhotosForm from "modules/website/pages/edit/components/photosForm";
import AddEventForm from "modules/website/pages/edit/components/addEventForm";
import UpdateForm from "modules/website/pages/edit/components/updateForm";
import Collapse from "shared/components/collapse";
import EditThemesForm from "modules/website/pages/edit/components/editThemesForm";
import UpdateAccountInfoForm from "modules/website/pages/edit/components/updateAccountInfoForm";
import { useSelector } from "react-redux";
import { getWebsite } from "shared/infra/redux/global/global";
import AddGames from "modules/website/pages/edit/components/addGames";

interface props {
    // data: any
    editType: "onboarding" | "design" | "content" | "account"
    children?: any
    styles?: any
    // setData: any
}

const MultiSectionEditor: React.FC<props> = ({editType}) => {
    const data: any = useSelector(getWebsite)
    const [closingCollapse, setClosingCollapse] = useState(false)

    // const isContentPage = () => editType === "content"
    const isAccountInfoPage = () => editType === "account"
    const tab = new URLSearchParams(window.location.search).get(
        'tab'
    );

    return (
        <>
            <Collapse 
                defaultActivePanel={isAccountInfoPage()? "9T56f" : tab === "theme" ? "8fe2a" : null}
                closingCollapse={closingCollapse}
                setClosingCollapse={setClosingCollapse}
                items={
                    ["onboarding", "content"].includes(editType) ?
                    [
                        { id: "35ab3", header: "Welcome Message", child: <CustomWelcomeMessageForm setClosingCollapse={setClosingCollapse}/>, description: "Edit your message so your Villie can celebrate with you.."},
                        { id: "2ff0b", header: "Funds + Registries", child: <BabyFundsForm setClosingCollapse={setClosingCollapse}/>, description: "You can edit how your Villie supports you!" },
                        { id: "b348d", header: "Photos", child: <PhotosForm />, description: "Let’s add more photos!" },
                        { id: "1fb9b", header: "Updates", child: <UpdateForm handleFormClosing={(arg: boolean) => setClosingCollapse(arg)} website={data}/>, description: "Let’s tell your Villie what’s going on. They love updates!"},
                        { id: "00dc7", header: `Games`, child: <AddGames setClosingCollapse={setClosingCollapse}/>, description: "Villagers love to play games!" },
                        { id: "8a154", header: "Events", child: <AddEventForm handleFormClosing={(arg: boolean) => setClosingCollapse(arg)} website={data}/>, description: "Let’s add any upcoming events for you and your Villie"},
                    ]
                    :
                    editType === "design" ?
                    [
                        { id: "8fe2a", header: "Theme", child: <EditThemesForm setClosingCollapse={setClosingCollapse}/>, description: "Your Villie is customizable to show your style! Edit themes and text so Villie-gers can celebrate with you."},
                        { id: "5156f", header: "Fonts + Colors", comingSoon: true},
                    ]
                    :
                    [ 
                        { id: "9T56f", header: "Edit Info", hideExpandIcon: true, dontClose: true, child:<UpdateAccountInfoForm />, description: "Your Villie is customizable to show your style! Edit themes and text so Villie-gers can celebrate with you."},
                    ]
            }
            />
        </>
        

    )
}


export default MultiSectionEditor;