import Preview from "../preview"
import "./styles.scss"
import React from 'react'

interface props{
    data: any
    selectedTheme: any
    setTheme: any
}

const Themes: React.FC<props> = ({data, selectedTheme, setTheme}) => {

    const themesList = [
        { id:"35ab3", name: "Winston Theme", colors: [{name: "Purple", value:"#FBF7FF"},{name: "Green", value:"#F0FFE4"},{name: "Yellow", value:"#FFFDE6"}] },
        { id:"2ff0b", name: "Rainbow Theme", colors:[{name: "Colorful", value: "linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%)"}, {name: "Nudes", value: "linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%)"}] },
        { id:"b348d", name: "Jungle Theme", colors: [{name: "Greenery", value: "#CCD5AE"}]},
        { id:"00dc7", name: "Classic Theme", colors: [{name: "Pink", value: "#FF8FAB"}, {name: "Blue", value: "#00B4D8"}] },
    ]

    return (
        <div className="themesContainer">
            {
                themesList?.map((theme: any) => (
                    <div className="theme">
                        <p className="name">{theme?.name}</p>
                        <div className="overview">
                            <Preview data={{...data, website_color: selectedTheme}} forThemes/>
                        </div>
                        <div className="colorsList">
                            {
                                theme?.colors?.map((color: {name: string, value: string}) => (
                                    <div className="color">
                                        <div className="sample" 
                                            style={{ border: color?.value === selectedTheme ? "3px solid #CC8DFF" : "1px solid #463649", background: color?.value }}
                                            onClick={() => setTheme(color?.value)}
                                            >
                                        </div>
                                        <div className="name">{color?.name}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Themes