import { app } from "shared/infra/config";
import Share from "../share";
import "./styles.scss";
import React from "react";

interface props{
    data: any
}
const ShareBox: React.FC<props> = ({data}) => {
    return (
        <>
            <div className="shareBox">
                <div className="text">{`${app?.APP_PUBLIC_URL}${data?.slug}`}</div>
                <Share data={data}/>
            </div>
        </>
    );
}
export default ShareBox;