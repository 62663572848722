import Button from "shared/components/bButton";
import "./styles.scss";
import React from "react";

const GuessWhoGameForm: React.FC = () => {
	return (
		<div className="guessWhoGameFormContainer">
			<div className="topImageBox">
				<img src="../assets/games/guess_who.png" alt="guess who game" />
			</div>
			<div className="questions">
				<p>Q1) Who will be the stricter parent?</p>
				<p>Q2) Who’s fashion sense will the baby have?</p>
				<p>Q3) Who wants more kids?</p>
				<p>Q4) Who will sing better lullabies?</p>
				<p>Q5) Who will take more photos?</p>
			</div>
			<Button
				type={"base4"}
				htmlType="submit"
				classes={["w-100-p", "m-t-3-p"]}
				text={`Create Game`}
			/>
		</div>
	);
};

export default GuessWhoGameForm;
