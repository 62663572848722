export type GlobalState = {
    isAddNotesModalVisible: boolean
    website: any
    refresh: boolean
    isUpdgradeModalVisible: boolean
}

const initialState: GlobalState = {
    isAddNotesModalVisible: false,
    website: null,
    refresh: false,
    isUpdgradeModalVisible: false
}

export default initialState