import "./styles.scss"
import MessageFromVillie from "shared/components/messageFromVillie"
import { Helmet } from 'react-helmet'
import React, { useEffect, useState } from 'react'
import Button from "shared/components/bButton"
import Divider from "shared/components/divider";
import Share from "shared/components/share";
import { Image } from "antd";
import Footer from "shared/components/nFooter";
import NavigationHeader from "shared/components/navigationHeader";
import { useNavigate } from "react-router-dom";
import { getUserWebsites } from "modules/website/redux/operators";
import ViewDonersModal from "./components/viewDonersModal";
import ViewSubscribersModal from "./components/viewSubscribersModal";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import { calculateTrimesterAndMop, formatDate, getFirstNameAndLastName, openInNewTab } from "shared/utils/helper";
import ViewVotersModal from "./components/viewVotersModal";
import useWindowSize from "shared/hooks/use-window-size";
import Preview from "shared/components/preview";
import Url from "shared/components/url"
import AddPassCodeModal from "./components/addPassCodeModal"
import UpgradeTierModal from "../newPreviewMode/modals/upgradeTierModal"
import { getUpgradeModalVisible, setUpgradeModalVisible } from "shared/infra/redux/global/global"

const Dashboard: React.FC = () => {
    const dispatch = useDispatch()
    const isUpgradeModalVisible = useSelector(getUpgradeModalVisible)
    const { width } = useWindowSize()
    const user = useSelector(getUser)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [websites, setWebsites] = useState<any>([])
    const [isViewDonersModalVisible, setIsViewDonersModalVisible] = useState(false)
    const [isViewSubscribersModalVisible, setIsViewSubscribersModalVisible] = useState(false)
    const [isAddPhoneNumberModalVisible, setIsAddPhoneNumberModalVisible] = useState(false);
    const [isViewVotersModalVisible, setIsViewVotersModalVisible] = useState(false)
    const [isAddPasscodeModalVisible, setIsAddPasscodeModalVisible] = useState(false)
    const [refetch, setRefetch] = useState(false)

    const fetchData = async () => {
        setIsLoading(true)
        const response = await getUserWebsites()
        setWebsites(response?.data)
        setIsLoading(false)

    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (refetch) {
            fetchData()
            setRefetch(false)
        }
    }, [refetch])

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <MessageFromVillie message='LET’S GET YOUR VILLAGE VILLAGING!
            ' />
            <div className="newDashboardContainer">

                {
                    isLoading ?
                        <div className="spinnerBox">
                            <Image preview={false} className="image" src="../assets/spinner.gif" />
                        </div>
                        :
                        websites?.map((website: any) => {
                            return (
                                <>
                                    <div className="newDashboardBoxForNavigation">
                                        <NavigationHeader title={"Your Dashboard"} website={website}/>
                                    </div>
                                    <div className="newDashboardBox">
                                        <div className="firstColumn">
                                            <div className="r1FirstColumn">
                                                <div className="previewBtn"
                                                    onClick={() => navigate(`/${website?.slug}?preview=true`)}
                                                >
                                                    Preview
                                                </div>
                                                <Preview data={website} forDashboard />
                                            </div>
                                            {/* <div className="r2FirstColumn">
                                                <Button
                                                    action={() => navigate("/edit/content")}
                                                    text="edit + Add Content"
                                                    type="base3"
                                                    classes={["r2FirstBtn"]}
                                                />
                                            </div> */}
                                            <Divider styles={{ backgroundColor: "#CC8DFF", height: "3px", margin: "1.5rem 0" }} />
                                            <div className="r3FirstColumn">
                                                <div className="firstRow">
                                                    <p className="url">https://village.villie.com/{website?.slug}</p>
                                                    <Url text="Edit URL" action={() => { 
                                                        openInNewTab('https://www.villie.com/contact')
                                                    }} />
                                                </div>

                                                <div className="secondRow">
                                                    <p onClick={() => { 
                                                        if(website?.package !== "tier1"){
                                                            return dispatch(setUpgradeModalVisible({ isUpdgradeModalVisible: true }))
                                                        }
                                                        setIsAddPasscodeModalVisible(true) 
                                                        }} className="passcode">
                                                        {
                                                            !!website?.password ? "Change passcode" : "Add a passcode"
                                                        }
                                                    </p>
                                                </div>
                                                
                                            </div>
                                            <div className="r4FirstColumn">
                                                <p className="heading">Share Villie</p>
                                                <p className="subheading">Share your page to increase contributions!</p>
                                                <div>
                                                    <Share data={website} />
                                                </div>
                                            </div>
                                            <div className="r5FirstColumn">
                                                <div className="ourOfferedFunds">
                                                    <p className="label">Villie’s Must-Haves</p>
                                                    {
                                                        [
                                                            ...(
                                                                ["Due date has passed", "Third trimester"].includes(calculateTrimesterAndMop(website?.due_date_or_dob)?.trimester)
                                                                    ? [
                                                                        { brandName: "SquareBaby Baby Food", brandDescription: "Fresh, yummy, organic baby food delivered right to your door", image: "square-baby.svg", url: "https://shrsl.com/4j4ka" },
                                                                        { brandName: "Zomee Breastfeeding Supplies", brandDescription: "Portable and quiet breast pumps and thoughtful accessories", image: "zomee-breast-pump.png", url: "https://zomee.com?sca_ref=5962469.zi4Cc5pHUT&utm_source=affiliate-villie-mom&utm_medium=socialmedia&utm_campaign=5962469&utm_term=influencer" },
                                                                    ]
                                                                    : [
                                                                        { brandName: "Believe Diapers", brandDescription: "Soft, hypoallergenic and absorbent bamboo diapers and wipes", image: "believe-diapers.svg", url: "https://rebrand.ly/hdecm1e" },
                                                                        { brandName: "Wellness", brandDescription: "The best prenatal vitamin out there. Plus help for nausea, iron and more", image: "women-fertility-trio.png", url: "https://fullwellllc.pxf.io/y2Xebb" },
                                                                    ]
                                                            )
                                                        ]?.map((offer: any) => (
                                                            <div className="offerBox">
                                                                <div className="left">
                                                                    <p className="heading">{offer?.brandName}</p>
                                                                    <p className="subheading">{offer?.brandDescription}</p>
                                                                    <Button
                                                                        type={"base4"}
                                                                        classes={['w-60-p']}
                                                                        text={`LEARN MORE`}
                                                                        action={() => openInNewTab(offer?.url)}
                                                                    />
                                                                </div>
                                                                <div className="right">
                                                                    <Image className="brandImage" src={`../assets/brands/${offer?.image}`} preview={false} />
                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                    {/* 
                                                    <div className="offerBox">
                                                        <div className="left">
                                                            <p className="heading">Believe Diapers</p>
                                                            <p className="subheading">Copy for Squarebaby Baby Food Insert here.....</p>
                                                            <Button
                                                                type={"base4"}
                                                                classes={['w-60-p']}
                                                                text={`Visit`}
                                                            />
                                                        </div>
                                                        <div className="right">
                                                            <Image className="brandImage" src="../assets/brands/believe-diapers.svg" preview={false}/>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="secondColumn">
                                            <div className="r1SecondColumn">
                                                <p className="heading">Your Funds</p>
                                                <div className="bottomArea">
                                                    <div><p style={{ display: "flex", gap: "2px" }}>Villie{website?.donations?.length > 0 && <>&nbsp;<Url text="view doners list" action={() => setIsViewDonersModalVisible(true)} /></>}</p><p>${(website?.total_contributions ?? 0).toFixed(2)}</p></div>
                                                    {!!website?.game[0]?.id && <div><p>Gender Reveal Game</p><p>${(website?.game[0]?.total_amount ?? 0).toFixed(2)}</p></div>}
                                                    <Divider styles={{ backgroundColor: "#E9C8CE", height: "3px", margin: "0.5rem 0" }} />
                                                    <div><p>Total Contributions</p><p>${(parseFloat(website?.total_contributions ?? 0) + parseFloat(website?.game[0]?.total_amount ?? 0)).toFixed(2)}</p></div>
                                                    <div><p>Gift Cards</p><p>${(parseFloat(website?.gifts_contributions ?? 0)).toFixed(2)}</p></div>
                                                    <div><p>Amount Cashout</p><p>${website?.cashouts?.reduce((total: any, item: any) => total + parseFloat(item.amount_paid), 0).toFixed(2)}</p></div>
                                                    <Divider styles={{ backgroundColor: "#E9C8CE", height: "3px", margin: "0.5rem 0" }} />
                                                    <div><p className="total">Remaining Amount</p><p>${((parseFloat(website?.total_contributions ?? 0) + parseFloat(website?.game[0]?.total_amount ?? 0)) - parseFloat(website?.gifts_contributions ?? 0) - website?.cashouts?.reduce((total: any, item: any) => total + parseFloat(item.amount_paid), 0))?.toFixed(2)}</p></div>
                                                    <Button
                                                        action={() => openInNewTab("https://www.villie.com/grg-cashout")}
                                                        text="Cash out"
                                                        type="base3"
                                                        classes={["w-100-p", "m-t-3-p"]}
                                                    />
                                                </div>
                                            </div>
                                            <Divider styles={{ backgroundColor: "#CC8DFF", height: "3px", margin: "1.5rem 0" }} />
                                            <div className="r1SecondColumn">
                                                <p className="heading">Your Villagers</p>
                                                <div className="topArea">
                                                    <div className="details">
                                                        <div>
                                                            <p className="headingTopArea">{`${getFirstNameAndLastName(user?.name).first_name}${!!website?.partner_name ? ` + ${getFirstNameAndLastName(website?.partner_name)?.first_name}` : ''}`} </p>
                                                            <p className="subheadingTopArea">{website?.city}, {website?.country}</p>
                                                        </div>
                                                        <div>
                                                            <p className="headingTopArea">{new Date(website?.due_date_or_dob) > new Date(website?.created_at) ? "Due Date" : "Birthdate"}:</p>
                                                            {/* <p className="subheadingTopArea">Jan 3, 2027</p> */}
                                                            <p className="subheadingTopArea">{formatDate(website?.due_date_or_dob)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bottomArea">
                                                    <Divider styles={{ backgroundColor: "#E9C8CE", height: "3px", margin: "0.5rem 0" }} />
                                                    <div><p className="total">Your Villagers</p><p>{(website?.guests?.length ?? 0)}</p></div>
                                                    <div className="m-t-3-p d-h-sb" style={{ flexDirection: width > 450 ? "row" : "column" }}>
                                                        <Button
                                                            classes={["w-60-p"]}
                                                            text="View full list"
                                                            action={() => setIsViewSubscribersModalVisible(true)}
                                                            type="base3"
                                                        />
                                                        <Url classes={["link"]} text="Add a Villager" action={() => setIsAddPhoneNumberModalVisible(true)} />
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                !!website?.game[0]?.id &&
                                                <>
                                                    <Divider styles={{ backgroundColor: "#CC8DFF", height: "3px", margin: "1.5rem 0" }} />
                                                    <div className="r1SecondColumn">
                                                        <p className="heading">Gender Reveal Game</p>
                                                        <p className="subheading">Share your Villie and Gender Reveal Game to increase your contributions!</p>
                                                        <div className="bottomArea">
                                                            <div><p>Total Amount Raised</p><p>${(website?.game[0]?.total_amount ?? 0).toFixed(2)}</p></div>
                                                            <Divider styles={{ backgroundColor: "#E9C8CE", height: "3px", margin: "0.5rem 0" }} />
                                                            <div><p className="total">Your Game Votes</p><p>{website?.game[0]?.voters?.length ?? 0}</p></div>
                                                            <Button
                                                                text="View full list"
                                                                type="base3"
                                                                action={() => setIsViewVotersModalVisible(true)}
                                                                classes={["w-100-p", "m-t-3-p"]}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                        <div className="thirdColumn">
                                        </div>
                                    </div>

                                    <div className="bottomDashboardBox">
                                        <Footer
                                            getHelp
                                            styles={{ width: "100%" }}
                                        />
                                    </div>

                                    <ViewSubscribersModal
                                        show={isViewSubscribersModalVisible}
                                        close={() => setIsViewSubscribersModalVisible(false)}
                                        website={website}
                                        isAddPhoneNumberModalVisible={isAddPhoneNumberModalVisible}
                                        setIsAddPhoneNumberModalVisible={setIsAddPhoneNumberModalVisible}
                                    />

                                    <ViewVotersModal
                                        show={isViewVotersModalVisible}
                                        close={() => setIsViewVotersModalVisible(false)}
                                        data={website?.game[0]?.voters}
                                    />

                                    <ViewDonersModal
                                        show={isViewDonersModalVisible}
                                        close={() => setIsViewDonersModalVisible(false)}
                                        data={website?.donations}
                                    />

                                    <AddPassCodeModal
                                        show={isAddPasscodeModalVisible}
                                        close={() => setIsAddPasscodeModalVisible(false)}
                                        refetch={() => setRefetch(true)}
                                        data={website}
                                    />

                                    <UpgradeTierModal
                                        show={isUpgradeModalVisible}
                                        close={() => dispatch(setUpgradeModalVisible({ isUpdgradeModalVisible: false }))}
                                        data={website}
                                    />
                                </>
                            )
                        })
                }
            </div>


        </>
    )
}

export default Dashboard