import "./styles.scss";
import React, { useEffect, useState, useRef } from "react";
import IntroSection from "./sections/introSection";
import ContributionSection from "./sections/contributionSection";
import EventSection from "./sections/eventSection";
import NotesSection from "./sections/notesSection";
import Actions from "shared/components/actions";
import UpdatesAndMileStoneSection from "./sections/updatesAndMilestoneSection";
import VilliePromoSection from "./sections/villiePromoSection";
import PhotosSection from "./sections/photosSection";
import GamesSection from "./sections/gamesSection";
import { useNavigate, useParams } from "react-router-dom";
import { findWebsite, updateWebsite } from "modules/website/redux/operators";
import { Image } from "antd";
import NoData from "shared/components/noData";
import { updateTheme } from "shared/utils/helper";
import { Helmet } from "react-helmet";
import AddNotesModal from "modules/website/pages/newPreviewMode/components/addNotesModal";
import {
	getIsAddNotesModalVisible,
	getUpgradeModalVisible,
	setIsAddNotesModalVisible,
	setUpgradeModalVisible,
} from "shared/infra/redux/global/global";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "shared/hooks/use-window-size";
import { getUser } from "modules/user/redux/userSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BButton from "shared/components/bButton";
import Notification from "shared/components/notification";
import Spinner from "shared/components/spinner";
import { themes } from "shared/utils/themeColors";
import TopBar from "./components/topbar";
import EnterPassCodeModal from "./modals/enterPascodeModal";
import UpgradeTierModal from "./modals/upgradeTierModal";

const NewPreviewModeContainer: React.FC = () => {
	const isUpdgradeModalVisible = useSelector(getUpgradeModalVisible)
	const { width } = useWindowSize();
	const user = useSelector(getUser);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notesModalIsVisible = useSelector(getIsAddNotesModalVisible);
	const { slug } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<any>(null);
	const [refresh, setRefresh] = useState(false);
	const [sectionsOrder, setSectionsOrder] = useState<string[]>([]);
	const [isOrderChanged, setIsOrderChanged] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [isEnterPasscodeModalVisible, setIsEnterPasscodeModalVisible] = useState(false)
	const colorIndexRef = useRef(0);

	const fetchData = async () => {
		setIsLoading(true);
		const response = await findWebsite(slug);
		setIsLoading(false);
		if (response?.status === "success") {
			handleResponse(response)
		} else {
			if (["Visitor token expired.", "Not the owner.", "Token is not avaliable."].includes(response?.message)) {
				setData(response?.data?.website);
				updateTheme(response?.data?.website?.website_color);
				setIsEnterPasscodeModalVisible(true)
			}
			if (response?.message?.includes("Website not found")) {
				navigate("/not-found");
			}
		}
	};

	const handleResponse = (response: any) => {
		setData(response?.data?.website);
		setSectionsOrder(
			response?.data?.website?.sections_order?.filter(
				(section: string) => section !== "IntroSection"
			)
		);
		updateTheme(response?.data?.website?.website_color);
	}

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}

		const newOrder = Array.from(sectionsOrder);
		const [removed] = newOrder.splice(result.source.index, 1);
		newOrder.splice(result.destination.index, 0, removed);

		setSectionsOrder(newOrder);
		setIsOrderChanged(true);
	};

	const renderSection = (section: string) => {
		const colors = themes[data?.website_color];
		const color = colors[colorIndexRef.current % colors.length];

		switch (section) {
			case "ContributionSection":
				colorIndexRef.current++;
				return (
					<ContributionSection
						website={data}
						setRefresh={() => setRefresh(true)}
						color={color}
					/>
				);
			case "EventSection":
				return <EventSection website={data} color={color} increaseIndex={() => colorIndexRef.current++}/>;
			case "NotesSection":
				return <NotesSection website={data} color={color} increaseIndex={() => colorIndexRef.current++}/>;
			case "UpdatesAndMileStoneSection":
				if (data?.updates?.length > 0) {
					colorIndexRef.current++;
					return (
						<UpdatesAndMileStoneSection
							website={data}
							color={color}
							setData={setData}
						/>
					);
				}
				return null;
			case "GamesSection":
				if (!!data?.game) {
					colorIndexRef.current++;
					return (
						<GamesSection
							website={data}
							color={color}
							setRefresh={() => setRefresh(true)}
						/>
					);
				}
				return null;
			case "PhotosSection":
				if (data?.photos?.length > 0) {
					colorIndexRef.current++;
					return <PhotosSection website={data} color={color} />;
				}
				return null;
			case "VilliePromoSection":
				colorIndexRef.current++;
				return <VilliePromoSection website={data} color={color} />;
			default:
				return null;
		}
	};

	const handleSaveOrder = async () => {
		if (data?.package !== "tier1") {
			return dispatch(setUpgradeModalVisible({ isUpdgradeModalVisible: true }));
		}
		setIsUpdateLoading(true);
		const response = await updateWebsite(data.id, {
			sections_order: JSON.stringify(sectionsOrder),
		});
		setIsUpdateLoading(false);

		if (response?.status === "success") {
			setIsOrderChanged(false);
			Notification("success", "Updated successfully.");
		} else {
			setIsUpdateLoading(false);
			Notification("error", "There is an error. Try again later.");
		}
	};

	useEffect(() => {
		if (!!slug) {
			fetchData();
		}
	}, [slug]);

	useEffect(() => {
		if (refresh) {
			fetchData();
			setRefresh(false);
		}
	}, [refresh]);


	return (
		<>
			{!!data?.slug && (
				<Helmet>
					<title key="title">{`Website - ${data?.slug}`}</title>
				</Helmet>
			)}

			{isLoading ? (
				<div className="spinnerBox">
					<Image
						preview={false}
						className="image"
						src="../assets/spinner.gif"
					/>
				</div>
			) : !!data ? (
				<>
					{!!user && user?.id === data?.user_id && (
						<TopBar website={data} />
					)}
					{!!user && user?.id === data?.user_id && isOrderChanged && (
						<div
							style={{
								backgroundColor: "rgba(56, 58, 61, 0.5)",
								width: "100%",
								height: "4.0625rem",
								top: width > 450 ? "9rem" : "4rem",
								zIndex: 10,
								position: "fixed",
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							<BButton
								text={isUpdateLoading ? <Spinner /> : "Save"}
								type="base3"
								action={handleSaveOrder}
							/>
						</div>
					)}
					<IntroSection website={data} />
					{!data?.hideOtherThings && <Actions website={data} />}

					{!!user && user?.id === data?.user_id ? (
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="sections">
								{(provided: any) => {
									colorIndexRef.current = 0; // Reset color index before rendering
									return (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{sectionsOrder.map((section, index) => (
												<Draggable
													key={section}
													draggableId={section}
													index={index}
												>
													{(provided: any) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															{renderSection(section)}
														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									);
								}}
							</Droppable>
						</DragDropContext>
					) : (
						<>
							{
								sectionsOrder.map((section, index) => {
									if (index === 0) colorIndexRef.current = 0; // Reset color index at the start
									return renderSection(section);
								})
							}
						</>
					)}

					<div style={{ height: "20vh" }}></div>

					<AddNotesModal
						show={notesModalIsVisible}
						close={() =>
							dispatch(
								setIsAddNotesModalVisible({
									isAddNotesModalVisible: false,
								})
							)
						}
						website={data}
					/>

					<EnterPassCodeModal
						show={isEnterPasscodeModalVisible}
						close={() => {}}
						data={data}
						handleResponse={(response: any) => {
							handleResponse(response);
							setIsEnterPasscodeModalVisible(false)
						}}
					/>

					<UpgradeTierModal
						show={isUpdgradeModalVisible}
						close={() => dispatch(setUpgradeModalVisible({ isUpdgradeModalVisible: false}))} 
						data={data}/>
				</>
			) : (
				<div className="spinnerBox">
					<NoData />
				</div>
			)}
		</>
	);
};

export default NewPreviewModeContainer;