import React from "react";
import "./styles.scss";
import BButton from "shared/components/bButton";
import Url from "shared/components/url";
import Share from "shared/components/share";
import useWindowSize from "shared/hooks/use-window-size";
import classNames from "classnames";
import useScrollPosition from "shared/hooks/use-scroll-position";
import { useNavigate } from "react-router-dom";
// import UpgradeTierModal from "../../modals/upgradeTierModal";
import { useDispatch } from "react-redux";
import { setUpgradeModalVisible } from "shared/infra/redux/global/global";
import { openInNewTab } from "shared/utils/helper";

interface TopBarProps {
	website: any;
}

const TopBar: React.FC<TopBarProps> = ({ website }) => {
	const { width } = useWindowSize();
	const { scrollY } = useScrollPosition();
	const isSticky = scrollY > 5;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const [showUpgradeModal, setShowUpgradeModal] = useState(false);
	return (
		<>
			<div
				className={classNames("topBarContainer", { sticky: isSticky })}
			>
				<div className="leftContainer">
					<BButton
						classes={["btn"]}
						icon={
							<img
								style={{
									marginRight: ".5rem",
								}}
								src="../assets/icons/backarrow.svg"
							/>
						}
						text={width > 450 ? "Exit Preview" : "Go Back"}
						type="base7"
						action={() => {
							navigate("/edit/content");
						}}
					/>
						<div className="upgradePlanBtn" 
							onClick={() => dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: true}))}
								style={{backgroundColor: website?.package === "tier1"? "#FFD700" : "#a3d080"}}
							>
                            <img className="icon" src={`../assets/newIcons/${website?.package === "tier1"? "super-villie" :"upgrade-icon"}.svg`} alt="heart" />
                        </div>
					<p className="titleText">Baby Page Preview</p>

					{width > 450 && (
						<>
							<p className="url">
								https://village.villie.com/{website?.slug}
							</p>
							<Url
								classes={["editurl"]}
								text="Edit URL"
								action={() => {
									openInNewTab("https://www.villie.com/contact")
								}}
							/>

							<Share data={website} shareIcon="white" />
						</>
					)}
				</div>
				<div className="rightContainer">
					<BButton
						classes={["upgradeBtn"]}
						text={website?.package === "tier1"? "Super Villie" : "Upgrade Plan"}
						icon={
							<img className="icon" src={`../assets/newIcons/${website?.package === "tier1"? "super-villie" :"upgrade-icon"}.svg`} alt="heart" />
						}
						type={website?.package === "tier1" ? "base8" : "base4"}
						action={() => {
							dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: true}))
						}}
						disabled={website?.package === "tier1"}
						styles={{cursor: "pointer"}}
					/>
				</div>
			</div>

			{/* <UpgradeTierModal 
				show={showUpgradeModal} 
				close={() => setShowUpgradeModal(false)} 
				data={website} /> */}
		</>
	);
};

export default TopBar;
