import React, { useEffect, useState } from 'react';
import "./styles.scss";
import MessageFromVillie from 'shared/components/messageFromVillie';
import MultiSectionEditor from 'shared/composedComponents/multiSectionEditor';
import Button from 'shared/components/bButton';
import PreviewBox from 'shared/composedComponents/previewBox';
import NavigationHeader from 'shared/components/navigationHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Divider from 'shared/components/divider';
import useWindowSize from 'shared/hooks/use-window-size';
import { findWebsiteByUser } from 'modules/website/redux/operators';
import { Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import { Helmet } from 'react-helmet'
import { getUpgradeModalVisible, getWebsite, getWebsiteRefresh, setUpgradeModalVisible, setWebsite, setWebsiteRefresh } from 'shared/infra/redux/global/global';
import UpgradeTierModal from '../newPreviewMode/modals/upgradeTierModal';

interface props {
    children?: any
    styles?: any
}

const Edit:React.FC<props> = () => {
    const isUpgradeModalVisible = useSelector(getUpgradeModalVisible)
    const user = useSelector(getUser)
    const refresh = useSelector(getWebsiteRefresh)
    const {width} = useWindowSize()
    const navigate = useNavigate()
    const { editType }: any = useParams();
    const [isLoading, setIsLoading] = useState(false)
    // const [data, setData] = useState(null)
    const data = useSelector(getWebsite)
    const dispatch = useDispatch()

    const getTitle = (editType: string) => {
        switch(editType){
            case "onboarding":
                return "Profile Set Up";
            case "content":
                return "Edit Content"; 
            case "design":
                return "Design";
            case "account":
                return "Account Info";
        }
    }

    const fetchData = async (userId: string) => {
        setIsLoading(true)
        const response = await findWebsiteByUser(userId)
        setIsLoading(false)
        if (response?.status === "success") {
            // setData(response?.data?.website)
            dispatch(setWebsite({website: response?.data?.website}))
        }
    }

    useEffect(() => {
        if (!!user?.id) {
            fetchData(user.id)
        }
    }, [user])

    useEffect(() => {
        if(refresh){
            if (!!user?.id) fetchData(user?.id)
            dispatch(setWebsiteRefresh({refresh: false}))
        }
    }, [refresh])


    return(
        <>
            <Helmet>
                <title>{getTitle(editType)}</title>
            </Helmet>
            <MessageFromVillie message='Let’s get your Village villaging!'/>
            <div className='editContainer'>
                {
                    isLoading ?
                    <div className="spinnerBox" style={{marginTop: "10%"}}>
                        <Image preview={false} className="image" src="../assets/spinner.gif" />
                    </div>
                    :
                    <div className='innerContainer'>
                    <NavigationHeader title={getTitle(editType)} website={data}/>
                    
                    <div className='middle'>
                        <div className='left'>
                            {/* <MultiSectionEditor editType={editType} data={data} setData={setData}/> */}
                            <MultiSectionEditor editType={editType} />
                            {
                                ["onboarding", "content"].includes(editType) &&
                                <Button 
                                    type="base3"
                                    classes={["btn"]}
                                    action={() => navigate("/dashboard")}
                                    text={`Dashboard`}
                                />
                            }
                        </div>
                        <div className='right' style={{marginBottom: editType === "onboarding" ? "1.5rem" : "0rem"}}>
                            <PreviewBox data={data}/>
                            {editType !== "onboarding" && width <= 450 && <Divider styles={{ backgroundColor: "#CC8DFF", height: "3px", margin: "25px 0" }} />}
                        </div>
                    </div>
                </div>
                }
                
            </div>

            <UpgradeTierModal 
                show={isUpgradeModalVisible} 
                close={() => dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: false}))} 
                data={data}
                
            />
        </>
    )
}


export default Edit;