import "./styles.scss";
import React from 'react';
import { Form, Input } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import classNames from "classnames";
import HelperText from "../helperText";

interface CustomInputProps {
    label?: string;
    name: string;
    placeholder?: string;
    minLength?: number;
    fixLength?: number;
    tooltip?: string;
    required?: boolean;
    errorMessage?: string;
    classes?: string[];
    isRounded?: boolean;
    minLengthFieldName?: string;
    fixLengthFieldName?: string;
    rules?: any[];
    styles?: any;
    helper?: any;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: React.FC<CustomInputProps> = ({
    label,
    name,
    placeholder,
    classes = [],
    minLength = 0,
    minLengthFieldName,
    fixLength = 0,
    fixLengthFieldName,
    rules = [],
    tooltip,
    required = false,
    errorMessage,
    isRounded = false,
    styles,
    helper,
    onChange,
}) => {
    const generateRules = () => {
        const customRules = [
            ...(required ? [{ required: true, message: errorMessage || "This is required" }] : []),
            ...(minLength
                ? [
                    {
                        validator: (_: any, value: any) => {
                            if (!value) {
                                return Promise.reject(false);
                            }
                            if (isNaN(value) || value.trim() === "") {
                                return Promise.reject(`${!minLengthFieldName ? "Phone number" : minLengthFieldName} must be numeric.`);
                            }
                            if (value.trim().length < minLength) {
                                return Promise.reject(`${!minLengthFieldName ? "Phone number" : minLengthFieldName} length must be greater than ${minLength - 1}.`);
                            }
                            return Promise.resolve(true);
                        },
                    },
                ]
                : []),
            ...(fixLength
                ? [
                    {
                        validator: (_: any, value: any) => {
                            if (!value) {
                                return Promise.reject(false);
                            }
                            if (isNaN(value) || value.trim() === "") {
                                return Promise.reject(`${fixLengthFieldName} must be numeric.`);
                            }
                            if (value.trim().length !== fixLength) {
                                return Promise.reject(`${fixLengthFieldName} must be ${fixLength} digits.`);
                            }
                            return Promise.resolve(true);
                        },
                    },
                ]
                : []),
            ...rules,
        ];

        return customRules;
    };

    return (
        <>
        <Form.Item
            label={label}
            name={name}
            rules={generateRules()}
            className="bInputContainer"
            tooltip={tooltip && {
                title: tooltip,
                icon: <InfoCircleFilled style={{ color: "#463649" }} />,
            }}
            style={{...styles, marginBottom: !!helper ? "10px" : ""}}
        >
            {
                name !== "password" ?
                <Input 
                    className={classNames('input', ...classes, { "isInputRounded": isRounded })} 
                    placeholder={placeholder} 
                    onChange={onChange}
                    
                />
                :
                <Input.Password className={classNames('input', ...classes, { "isInputRounded": isRounded })} placeholder={placeholder} onChange={onChange}/>
            }
        </Form.Item>
        {!!helper && <HelperText text={helper} styles={{ paddingBottom: "16px"}}/>}
        </>
    );
};

export default CustomInput;
