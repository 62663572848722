import "./styles.scss";
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import Input from "shared/components/binput"
import Button from "shared/components/bButton";
import UploadImagesForOneImage from "shared/components/uploadImagesForOneImage";
import Spinner from "shared/components/spinner";
// import { blobToBase64 } from "shared/utils/helper";
import TextArea from "shared/components/btextArea";
import { newConvertBlobToBase64 } from "shared/utils/helper";
import { addNewUpdate, deleteUpdate, editUpdate } from "modules/website/redux/operators";
import { Mixpanel } from "shared/services/mixpanel";
import { useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import Notification from "shared/components/notification";
import useWindowSize from "shared/hooks/use-window-size";
// import { getWebsite } from "shared/infra/redux/global/global";

interface props{
    data?: any
    handleFormClosing?: any
    isUpdating?: boolean
    setData?: any
    website?: any
}
const UpdateForm: React.FC<props> = ({handleFormClosing, isUpdating = false, website, data, setData}) => {
    // const data: any = useSelector(getWebsite)
    const {width} = useWindowSize()
    const user = useSelector(getUser)
    const [form] = Form.useForm();
    const [images, setImages] = useState<any>([])
    const [deletedImages, setDeletedImages] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const handleAddAnUpdate = async (values: any) => {
        if(isLoading) return

        const payload = {
            "update_photos": await newConvertBlobToBase64(images),
            ...values,
        }

        setIsLoading(true)
        const response = await addNewUpdate(website?.id, payload)
        setIsLoading(false)

        if (response?.status === "success") {
            Mixpanel.track(
                "PARENT_adds_an_update",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )
            setImages([])
            form.resetFields()
            handleFormClosing(true)
            Notification("success", "Update added successfully")
        }
        else {
            Notification("error", response?.message)
        }

    }

    const handleEditAnUpdate = async (values: any) => {
        if(isLoading) return

        const payload = {
            "update_photos": [
                ...deletedImages,
                ...await newConvertBlobToBase64(images?.filter((i: any) => i?.blob))
            ],
            ...values,
        }

        setIsLoading(true)
        const response = await editUpdate(data?.website_id, data?.id, payload)
        setIsLoading(false)

        if (response?.status === "success") {
            Mixpanel.track(
                "PARENT_edit_an_update",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )
            setData({...website,
                updates: website?.updates?.map((u: any) => u?.id === data?.id ? {...u, ...response?.data?.update, images: [...images?.filter((i: any) => !i?.blob), ...response?.data?.update?.images] } : u
                )
            })
            setImages([])
            form.resetFields()
            handleFormClosing()
            Notification("success", "Update edit successfully")
        }
        else {
            Notification("error", response?.message)
        }

    }

    const handleDelete = async () => {
        setIsDeleting(true)
        const response = await deleteUpdate(data?.website_id, data?.id)

        if(response?.status === "success"){
            Mixpanel.track(
                "PARENT_delete_an_update",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )
            setData({...website, updates: website?.updates?.filter((u: any) => u?.id !== data?.id)})
            handleFormClosing()
            Notification("success", "Update deleted successfully")
        }
        else{
            Notification("error", response?.message)
        }
        setIsDeleting(false)
    }

    useEffect(() => {
        if (isUpdating) {
            form.setFieldsValue({
                title: data?.title,
                message: data?.message
            });

            if(data?.images?.length > 0){
                setImages(data?.images)
            }
            else{
                setImages([])
            }
        }
    }, [isUpdating, data]);


    return (
        <>
            <Form
                name="addUpdateForm"
                onFinish={isUpdating ? handleEditAnUpdate : handleAddAnUpdate}
                autoComplete="off"
                form={form}
                layout={"vertical"}
            >
                <div className='addUpdateMilestoneFormContainer'>
                    <Input
                        label="Give the update a title"
                        name="title"
                        placeholder='Ex: We’re coming home!'
                    />
                    
                    <TextArea
                        label="Additional message"
                        name="message"
                        placeholder="Ex: We got home today and Baby met Fido"
                        required
                    />
                    <UploadImagesForOneImage
                        full={true}
                        name="Photos"
                        label="Add an image"
                        images={images}
                        onChange={(images: any) => setImages(images)}
                        onDelete={(image: any) => setDeletedImages(image)}
                        deletedImages={deletedImages}
                        noOfImagesAllowed={1}
                        btnSublabel={""}
                    />
                    
                    {
                        isUpdating? 
                        <div className={width > 450 ? "d-h-sb" : "d-v-c"}>
                            <Button
                                classes={[width > 450 ? "w-48-p" : "w-100-p", "m-t-3-p"]}
                                type="base4"
                                htmlType="submit"
                                text={isLoading ? <Spinner /> : `Edit update`}
                            />
                            <Button
                                classes={[width > 450 ? "w-48-p" : "w-100-p", "m-t-3-p"]}
                                type="base5"
                                action={handleDelete}
                                text={isDeleting ? <Spinner /> : `Delete update`}
                            />
                        </div>
                        :
                        <Button
                            classes={["w-100-p", "m-t-3-p"]}
                            type="base4"
                            htmlType="submit"
                            text={isLoading ? <Spinner /> : `Add update`}
                        />
                    }
                    
                    
                </div>
            </Form>
        </>
    )
}


export default UpdateForm;