import "./styles.scss";
import React, { useState } from 'react'
import CParagraph from "shared/components/paragraph";
import useWindowSize from "shared/hooks/use-window-size";
import { Col, Row, Image, Typography } from 'antd';
import Button from "shared/components/bButton";
import Section from "shared/components/section";
import FollowOurVillieModal from "../../modals/followOurVillieModal";
import PersonalInfoWidget from "shared/components/personalInfoWidget";
import Navbar from "shared/components/navbar";
import { introSectionColor } from "shared/utils/themeColors";
const { Paragraph } = Typography

interface props {
    website: any
}

const IntroSection: React.FC<props> = ({ website }) => {
    const [randomImage, _] = useState(website?.slug?.length % 3)
    const { width } = useWindowSize()
    const [isFollowOurVillieModalVisible, setIsFollowOurVillieModalVisible] = useState(false)

    return (
        <>
            <Section 
                styles={width > 450 ? {} : { marginTop: "0", borderRadius: "0", borderBottomLeftRadius: "30px", borderBottomRightRadius: "30px" }}
                backgroundColor={introSectionColor[website?.website_color]}
                >
                <Row className='introSectionContainer'>
                    <Col span={24}>
                        <Navbar website={website} />
                    </Col>
                    <Col span={24}>
                        {/* <Row className="hero" justify="space-between" align="middle"> */}
                        <div className="hero">
                            {/* <Col xs={24} sm={24} md={9} lg={9} xl={9} className="leftSide"> */}
                            <div className="leftSide">
                                <CParagraph classes={["headline"]}>{!!website?.title ? website?.title : <>Welcome to Our <span className="spanText">Villie</span></>}</CParagraph>
                                <div className="personalInfo">
                                    <div className="parentInfoBox">
                                        <PersonalInfoWidget website={website} />
                                    </div>
                                    <Image className="image"
                                        src={website?.photos?.[0]?.img_url || `../assets/placeholders/villie/image${randomImage}.png`}
                                        alt="villie-image" preview={false}
                                    />
                                </div>

                                {website?.supporters > 0 && (
                                    <div className="supportorsCountsButton">
                                        <div>{website?.supporters}</div>
                                        <div>Villagers</div> 
                                    </div>
                                )}

                                <Button classes={["journeyBtn"]} type={"theme"} text="Follow"
                                    action={() => setIsFollowOurVillieModalVisible(true)}
                                />
                                
                                <Paragraph ellipsis={
                                    {
                                        rows: width > 450 ? 2 : 4,
                                        expandable: true,
                                        symbol: "MORE...",
                                    }
                                } className="discription">{website?.description}</Paragraph>
                            </div>
                            <div className="rightSide">
                                {/* <Col span={13} className="rightSide"> */}
                                <div className="parentInfoBox">
                                    <PersonalInfoWidget website={website} />
                                </div>
                                <Image className="image"
                                    src={website?.photos?.[0]?.img_url || `../assets/placeholders/villie/image${randomImage}.png`}
                                    alt="villie-image" preview={false}
                                />
                            </div>
                        </div>

                        {/* </Row> */}
                    </Col>
                </Row>
            </Section>

            <FollowOurVillieModal
                website={website}
                show={isFollowOurVillieModalVisible}
                close={() => setIsFollowOurVillieModalVisible(false)}
            />
        </>
    )
}

export default IntroSection