import "./styles.scss";
import React from "react";
import { Row, Col, Divider } from "antd";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypal } from "shared/infra/config";
import Paypal from "./paypal";
import Stripe from "./stripe";
import { updateWebsite } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { DATETIME, getQueryParam } from "shared/utils/helper";
import { useNavigate } from "react-router-dom";

interface props {
	amountCharged?: any;
	stripeClientSecret?: any;
	data?: any;
	handlePaymentComplete: any
}

const paypalScriptOptions = {
	"client-id": paypal.PAYPAL_KEY!,
	currency: "USD",
};

const Payment: React.FC<props> = ({amountCharged, stripeClientSecret, data, handlePaymentComplete}) => {
	const navigate = useNavigate()
	const websiteSlug: any = getQueryParam('website_slug');

	const handleUpgrade = async (payload: any) => {
		const response = await updateWebsite(data.id, {
			package: payload?.status === "success" ? data.package : "tier0",
			package_payment_id: payload?.payment_id,
			package_payment_status: payload?.status,
			package_updated_on: DATETIME
		});

		if (response?.status === "success") {
			handlePaymentComplete(payload?.status)

			setTimeout(
				() => navigate(`/${websiteSlug}`),
				2000
			);
		} else {
			Notification("error", response?.message);
		}
	};

	return (
		<>
			<Row className="upgradePaymentContainer">
				<Col span={24}>
					<PayPalScriptProvider options={paypalScriptOptions}>
						<Paypal
							handlePayment={handleUpgrade}
							amount={parseFloat(amountCharged)}
						/>
					</PayPalScriptProvider>
				</Col>
				<Col span={24}>
					<Divider plain>or</Divider>
				</Col>

				<Col span={24}>
					<Stripe
						stripeClientSecret={stripeClientSecret}
						handlePayment={handleUpgrade}
						data={data}
					/>
				</Col>
			</Row>
		</>
	);
};

export default Payment;
