import "./styles.scss";
import React, { useState } from 'react'
import { Col, Row } from 'antd';
import Section from "shared/components/section";
import Heading from "shared/components/heading";
import Button from "shared/components/bButton";
import Update from "shared/composedComponents/update";
import ViewUpdatesAndMilestonesModal from "../../modals/viewUpdatesAndMilestonesModal";
import { getFirstNameAndLastName } from "shared/utils/helper";
import EditUpdateModal from "../../components/editUpdateModal";
import { getBtnBackgroundColor } from "shared/utils/helper";
import { getTextColor } from "shared/utils/helper";

interface props {
    website: any
    setData: any
    color?: string
}
const UpdatesAndMileStoneSection: React.FC<props> = ({website, setData, color}) => {
    const [isViewUpdatesAndMilestonesModalVisible, setIsViewUpdatesAndMilestonesModalVisible] = useState(false)
    const [isUpdateUpdatesModalVisible, setIsUpdateUpdatesModalVisible] = useState(false)
    const [editUpdate, setEditUpdate] = useState(null)

    const handleEditUpdate = (update: any) => {
        setEditUpdate(update)
        setIsUpdateUpdatesModalVisible(true)
    }

    return (
        <>
            <div id="updates"></div>
            <Section backgroundColor={color}>
                <Row className='updatesAndMilestoneSectionContainer' justify="space-between">
                    <Col span={24}>
                        <Heading styles={getTextColor(website?.website_color, color)} text={<>{getFirstNameAndLastName(website?.village_owner).first_name}’s <span
                            className={"spanText"}
                            style={getTextColor(website?.website_color, color) || {undefined}}
                            >Updates</span></>}/> </Col>
                    <Col span={24}>
                        <Row justify="space-between" gutter={[0,24]}>
                            { website?.updates?.length > 0 && <Col xs={24} sm={24} md={11} lg={11} xl={11}><Update data={website?.updates[0]} handleEditUpdate={handleEditUpdate} website={website}/></Col>}
                            { website?.updates?.length > 1 && <Col xs={24} sm={24} md={11} lg={11} xl={11}><Update data={website?.updates[1]} handleEditUpdate={handleEditUpdate} website={website}/></Col>}
                        </Row>
                    </Col>

                    <Col span={24} className="lastRow">
                        <Button styles={getBtnBackgroundColor(website?.website_color, color)} classes={["btn"]} type="theme" text="view all updates + milestones" action={() => setIsViewUpdatesAndMilestonesModalVisible(true)}/>
                    </Col>
                </Row>
            </Section>
            <ViewUpdatesAndMilestonesModal 
                show={isViewUpdatesAndMilestonesModalVisible}
                close={() => setIsViewUpdatesAndMilestonesModalVisible(false)}
                website={website}
                handleEditUpdate={handleEditUpdate}
            />
            <EditUpdateModal 
                close={() => {
                    setIsUpdateUpdatesModalVisible(false)
                    setEditUpdate(null)
                }}
                show={isUpdateUpdatesModalVisible}
                editUpdate={editUpdate}
                website={website}
                setData={setData}
            />
        </>
    )
}

export default UpdatesAndMileStoneSection