import "./styles.scss";
import React, { useState } from 'react';
import Button from "shared/components/bButton";
import DonationDropdown from "shared/components/donationDropdown";
import classNames from "classnames";
import DecisionButtons from "shared/composedComponents/decisionButtons";
import { updateWebsite } from "modules/website/redux/operators";
import Spinner from "shared/components/spinner";
import Notification from "shared/components/notification";
import { capitalize } from "shared/utils/helper";
import { Mixpanel } from "shared/services/mixpanel";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import { getWebsite, setWebsite } from "shared/infra/redux/global/global";
import Url from "shared/components/url";
import DeleteRegistry from "../deleteRegistry";
import AddRegistryForm from "../addRegistryForm";
import EditRegistryModal from "../../modals/editRegistryModal";

interface props{
    setClosingCollapse: any
}

const BabyFundsForm: React.FC<props> = ({setClosingCollapse}) => {
    const dispatch = useDispatch()
    const data: any = useSelector(getWebsite)
    const user = useSelector(getUser)
    const [receiveFund, setReceivedFunds] = useState(data?.receive_fund)
    const [fundType, setFundType] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddRegistryVisible, setIsAddRegistryVisible] = useState(false)
    const [isDeleteRegistryConfirmationVisible, setIsDeleteRegistryConfirmationVisible] = useState(false)
    const [registryOperationData, setRegistryOperationData] = useState(null)
    const [isEditRegistryModalVisible, setIsEditRegistryModalVisible] = useState(false)

    const handleFunds = async () => {
        const payload: any = {
            receive_fund: receiveFund
        }

        if(receiveFund && fundType != 'Select Your Fund'){
            payload["fund_type"] = fundType
        }

        setIsLoading(true)
        const response = await updateWebsite(data.id, payload)
        setIsLoading(false)

        if (response?.status === "success") {
            if(payload?.receive_fund){
                Mixpanel.track(
                    "PARENT_adds_a_fund",
                    {
                        $name: user?.name,
                        $email: user?.email
                    }
                )
            }
            
            dispatch(setWebsite({website: {...data, ...payload}}))
            setClosingCollapse(true)
            Notification("success", "Updated successfully.")

        }
        else {
            setIsLoading(false)
            Notification("error", "There is an error. Try again later.")
        }

    }

    return (
        <>
            <div className="babyFundsFormContainer">
                <DecisionButtons 
                    label="Do you want to accept donations? You can cash out anytime."
                    actionOnYes={() => setReceivedFunds(true)}
                    actionOnNo={() => setReceivedFunds(false)}
                    decision={receiveFund ? "Yes" : "No"}
                />
                
                {
                    receiveFund == true &&
                    <>
                        <DonationDropdown
                            funds={['Select Your Fund', 'Diaper Fund', 'Baby Fund', 'Education Fund']}
                            setFundType={(fundType: string) => setFundType(fundType)}
                            fundType={fundType}
                        />
                    </>
                }

                <div className="registryBoxFunds">
                    <p className="label">Baby Registry</p>
                    <div className={classNames("registryArea", {"isRegistryAreaVisible" : isAddRegistryVisible})}>
                        <div className={classNames("topAreaRegistry", {"isTopAreaRegistryVisible": isAddRegistryVisible})}>
                            <p className="headingRegistryArea">Add a Baby Registry</p>
                            <img 
                                className="iconRegistryArea" 
                                src="../assets/newIcons/plus.svg" 
                                onClick={() => setIsAddRegistryVisible(!isAddRegistryVisible)}
                                alt="add-registry-icon"
                            />
                        </div>
                        <div className={classNames("bottomAreaRegistry", {"isBottomAreaRegistryVisible" : isAddRegistryVisible})}>
                            <AddRegistryForm 
                                data={data}
                                successAction={() => setIsAddRegistryVisible(false)}
                            />
                        </div>
                        
                    </div>
                </div>

                <div className="registryDisplayBox">
                    {
                        data?.registries?.map((registry : any) => (
                            <div className="registry">
                                <img className="deleteBtn" 
                                    src="../assets/newIcons/delete.svg"
                                    onClick={() => {
                                        setRegistryOperationData(registry)
                                        setIsDeleteRegistryConfirmationVisible(true)
                                    }}
                                />
                                {

                                    registry?.registry_brand !== "Other" ? 
                                    <img
                                        src={`../assets/registries/${capitalize(registry?.registry_brand)}.svg`}
                                        className="img"
                                    />
                                    :
                                    <p>Registry</p>
                                }
                                
                                <Url text="Edit" action={() => {
                                    setIsEditRegistryModalVisible(true)
                                    setRegistryOperationData(registry)
                                }}/>
                            </div>
                        ))
                    }
                    
                </div>
                <Button
                    type={"base4"}
                    // htmlType="submit"
                    classes={["w-100-p", "m-t-3-p"]}
                    text={isLoading? <Spinner/> : `Save`}
                    action={handleFunds}
                />
            </div>

            <DeleteRegistry 
                show={isDeleteRegistryConfirmationVisible}
                close={() => {
                    setRegistryOperationData(null)
                    setIsDeleteRegistryConfirmationVisible(false)
                }}
                registry={registryOperationData}
                data={data}
            />

            <EditRegistryModal 
                show={isEditRegistryModalVisible}
                close={() => {
                    setRegistryOperationData(null)
                    setIsEditRegistryModalVisible(false)
                }}
                registry={registryOperationData}
                data={data}
            />
        </>
    )
}


export default BabyFundsForm;