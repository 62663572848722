import "./styles.scss";
import React, { useState } from 'react';
import Modal from 'shared/components/modal';
import { setMainImage } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { useDispatch } from "react-redux";
import { setWebsite } from "shared/infra/redux/global/global";
import Confirmation from "shared/components/confirmation";

interface props {
    show: boolean
    close: any
    photo: any
    data: any
    mainPhoto: any
}

const SetMainImageModal: React.FC<props> = ({show, close, data, mainPhoto}) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
    const [mainImageData, setMainImageData] = useState<any>(null)

    const handleChangeMainImage = async () => {
        if(isLoading) return 0
        
        setIsLoading(true)
        const response = await setMainImage(mainImageData?.website_id, mainImageData?.id)
        setIsLoading(false)

        if(response?.status === "success"){
            const temp = response.data[0];
            const updatedPhotos = data.photos.map((p: any) => 
                p.id === temp.id ? temp : p
            );

            dispatch(setWebsite({website: { ...data, photos: updatedPhotos }}));
            
            Notification("success", "Main image change successfully")
            setIsConfirmationVisible(false)
        }
        else{
            Notification("error", response?.message)
        }
    }

    return (
        <>
            <Modal show={show} close={close} head={false} title={``} classes={[""]}> 
                <div className='setMainImageModalContainer'>
                    <p className="headline">Choose new main image</p>
                    <div className="imagesDisplayBox">
                    {
                        data?.photos?.map((photo: any) => (
                            <div className="imageBox" key={photo?.id}>
                                <img 
                                    className="img" 
                                    src={photo?.img_url}
                                    onClick={() => {
                                        setIsConfirmationVisible(true)
                                        setMainImageData(photo)
                                    }}
                                />
                                {mainPhoto?.id === photo?.id && <div className="badge">Main</div>}
                            </div>
                        ))
                    }
                    </div>
                </div>
            </Modal>

            <Confirmation
                heading="Are you sure you want to change main change?" 
                show={isConfirmationVisible}
                close={() => setIsConfirmationVisible(false)}
                okAction={handleChangeMainImage}
                isOkLoading={isLoading}
            />
        </>
    )
}

export default SetMainImageModal;