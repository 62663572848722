import React from 'react';
import "./styles.scss";

interface props {
    message: string
    styles?: any
}

const MessageFromVillie:React.FC<props> = ({message, styles}) => {
    return(
        <>
            <p className='messageFromVillieContainer' style={{...styles}}>
                {message}
            </p>
        </>
    )
}


export default MessageFromVillie;