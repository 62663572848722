import "./styles.scss";
import React, {useState} from 'react';
import { Dropdown, Menu, Button as AntdButton, Input } from 'antd';
import Button from "shared/components/bButton";


interface props {
    fundType: any
    setFundType: any
    funds: any
}

const DonationDropdown: React.FC<props> = ({setFundType, fundType, funds}) => {
    const [selectedOption, setSelectedOption] = useState(!!fundType ? fundType : funds[0]);
    const [customOption, setCustomOption] = useState('');
    const [visible, setVisible] = useState(false);

    const handleMenuClick = (e: any) => {
        setSelectedOption(e.key);
        setFundType(e.key)
        setVisible(false)
    };

    const handleInputChange = (e: any) => {
        setCustomOption(e.target.value);
    };

    const handleButtonClick = (e: any) => {
        e.preventDefault()
        setSelectedOption(customOption)
        setFundType(customOption)
        setVisible(false)
        setCustomOption('')
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {funds?.map((option: any) => (
                <Menu.Item key={option}>{option}</Menu.Item>
            ))}
            <Menu.Item style={{ padding: '8px 12px' }}>
                <Input 
                    className="customFundInput" 
                    placeholder="Add a custom fund" 
                    onClick={(e) => e.stopPropagation()} 
                    onChange={handleInputChange}
                    value={customOption}
                />
            </Menu.Item>
            <div style={{ padding: '8px 12px' }}>
                <Button
                    classes={["w-100-p"]}
                    text={"Save Fund"}
                    type="base4"
                    action={handleButtonClick}
                />
            </div>
                
        </Menu>
    );

    return (
        <>
           <Dropdown overlay={menu} className="donationDropdownContainer" visible={visible}>
                <AntdButton onClick={(e: any) => {e.preventDefault(); setVisible(!visible)}}>
                    {selectedOption}
                        <img 
                            style={{width: "24px", height:"24px"}}
                            className='iconPanel'
                            src={`../assets/newIcons/collapse-${visible ? "close" : "open"}.svg`} 
                        />
                </AntdButton>
            </Dropdown>
        </>
    )
}

export default DonationDropdown;