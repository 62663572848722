import "./styles.scss";
import React from "react";
import {
    WhatsappShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    // TwitterShareButton,

    WhatsappIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    // XIcon,
} from "react-share";
import { Mixpanel } from "shared/services/mixpanel";
import { useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import { app } from "shared/infra/config"
import Notification from "shared/components/notification";
import { capitalize } from "shared/utils/helper";

interface props {
    data: any
    shareIcon?: "black" | "white"
}

const Share: React.FC<props> = ({data, shareIcon="black"}) => {
    const user = useSelector(getUser)


    const handleShares = (platform: string) => {
        Mixpanel.track(
            "PARENT_shares_villie",
            {
                $name: user?.name,
                $email: user?.email,
                platform: platform
            }
        )
    }

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(`${app?.APP_PUBLIC_URL}${data?.slug}`)
            .then(() => {
                Notification("success", "Url copied successfully");
            })
            .catch(() => {
                Notification("error", "Could not copy URL");
            });
    };


    return (
        <>

            <div className="socialIconsBox">
                <WhatsappShareButton
                    url={`${app?.APP_PUBLIC_URL}${data?.slug}`}
                    title={`You are my Village! Check out my Villie page to stay up to date on all things Baby. I’ll share updates, pictures and more. Click “Join my Village” to subscribe.`}
                    onClick={() => handleShares("whatsapp")}
                >
                    <WhatsappIcon size={"1.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
                </WhatsappShareButton>
                <FacebookShareButton onClick={() => handleShares("facebook")} url={`${app?.APP_PUBLIC_URL}${data?.slug}`} hashtag={"#villie"}>
                    <FacebookIcon size={"1.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
                </FacebookShareButton>
                <FacebookMessengerShareButton onClick={() => handleShares("facebook_messanger")} appId="1111927622279359" url={`${app?.APP_PUBLIC_URL}${data?.slug}`} >
                    <FacebookMessengerIcon size={"1.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
                </FacebookMessengerShareButton>
                {/* <TwitterShareButton onClick={() => handleShares("twitter")} url={`${app?.APP_PUBLIC_URL}${website?.slug}`} title={You are my Village! Check out my Villie page to stay up to date on all things Baby. I’ll share updates, pictures and more. Click “Join my Village” to subscribe.}>
                    <XIcon size={"1.5rem"} round={true} />
                </TwitterShareButton> */}
                <img className="shareIcon" alt="share-icon" src={`../assets/newIcons/share${capitalize(shareIcon)}.svg`} onClick={handleCopyUrl} />
            </div>
        </>
    );
}
export default Share;