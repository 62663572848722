import "./styles.scss";
import React from 'react';
import ProfileDropdown from "shared/components/profileDropdown";
import Button from "shared/components/bButton";
import { Image } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import useWindowSize from "shared/hooks/use-window-size";
import { Link, useNavigate } from "react-router-dom";
import { setIsAddNotesModalVisible } from "shared/infra/redux/global/global";
import useScrollPosition from "shared/hooks/use-scroll-position";
import classNames from "classnames";

interface props {
    website?: any
    hideDesktopTabs?: boolean
}

const Navbar: React.FC<props> = ({hideDesktopTabs = false, website}) => {
    const user = useSelector(getUser)
    const { width } = useWindowSize()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { scrollY } = useScrollPosition();
    const isSticky = scrollY > 5;

    return (
        <>
            <div className={classNames("navbar", {"sticky" : isSticky && width > 450})}>
                <div className="rightSide">
                    <Link to="/dashboard">
                        <Image className="logo" src={"../assets/villie.svg"} alt="logo" preview={false} />
                    </Link>
                    {
                        !hideDesktopTabs &&
                        <>
                            {[
                                { text: "Updates", url: "#updates", visible: website?.updates?.length > 0},
                                { text: "Gifts", url: "#gifts", visible: (website?.country === "United States" || website?.registries?.length > 0 || !!website?.receive_fund)},
                                { text: "Events", url: "#events", visible: website?.milestones?.length > 0},
                                { text: "Photos", url: "#photos", visible: website?.photos?.length > 0},
                                { text: "Games", url: "#games", visible: !!website?.game},
                            ]?.filter(tab => tab.visible)?.map((t: any) => (<a className="tab" href={t?.url}>{t?.text}</a>))}

                            <p className="tab" onClick={() => dispatch(setIsAddNotesModalVisible({ isAddNotesModalVisible: true }))}>
                                Add note
                            </p>
                        </>
                    }
                </div>

                <div className="rightSide">
                    {!user ?
                        <>
                            <p className="tab" onClick={() => navigate("/login")}>
                                Login
                            </p>
                            <Button

                                type="base1"
                                text={width <= 450 ? "LOGIN" : "CREATE A BABY PAGE"}
                                action={() => navigate(width <= 450 ? "/login" : "/signup")}
                            />
                        </>
                        :
                        <ProfileDropdown website={website}/>
                    }

                </div>
            </div>
        </>
    )
}

export default Navbar;